<!-- 收藏的资料 -->
<template>
  <div class="browsePost">
    <!-- <p class="myclass-title">
      <span class="title-text">收藏的资料</span>
      <span class="title-border"></span>
    </p> -->
    <headers ref="headers" :headname="headName"></headers>

    <!-- 有数据展示 -->
    <div v-show="count > 0" class="collect_list">
      <div v-for="(item,index) in list" :key="index" class="zhul_sy_zyList" style="display: flex;">

        <div v-if="item.pic && item.pic.length>0" class="zhul_nzy_limg">
          <img :src="item.pic" alt="" @click="threadDetail(item)">
        </div>

        <div :class="[item.pic&&item.pic.length>0?&quot;zhul_nzy_rinfo&quot;:&quot;zhul_nzy_rinfo_long&quot;]">
          <div class="zhul_zy_rtop">
            <h3 class="zhul_zy_rtit" @click="threadDetail(item)">
              {{ item.title }}
            </h3>
            <p v-show="item.hot!==0" class="zhul_zy_hot1">{{ item.hot }}</p>
            <p v-show="item.is_essence===1" class="zhul_zy_hot">精</p>
          </div>

          <div class="item_msg" style="width:auto;">
            <span>{{ item.username }}</span>
            <span class="datespan">发表于{{ item.create_time | formatDate }}</span>
            <div class="m_l">
              <img src="@/assets/image/look.png" alt="">
              <span>{{ item.view_num }}人浏览</span>
            </div>
            <div class="m_l">
              <img src="@/assets/image/pinglun.png" alt="">
              <span>{{ item.reply_num }}人跟帖</span>
            </div>
          </div>

          <p class="content">
            {{ item.content }}
          </p>

          <p class="zhul_lb_reply">
            最新回复：
            <!-- @click="userArchive(item.last_uid)" -->
            <span class="last_user">{{ item.last_username }}</span>
            <span class="reply_name">{{ item.last_time|formatDate }}</span>
            <img v-if="item.from_sys===0" src="@/assets/image/diannao.gif" title="电脑版">
            <a v-else-if="item.from_sys===1" href=""><img src="@/assets/image/qz_tzios.gif" title="苹果手机APP"></a>
            <a v-else href=""><img src="@/assets/image/qz_tzandroid.gif" title="安卓手机APP"></a>
          </p>

        </div>
      </div>

      <!-- 分页 -->
      <div class="feny">
        <el-pagination
          v-show="count>10"
          class="page"
          background
          layout="prev, pager, next"
          :page-size="params.limit"
          :total="count"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>

    </div>

    <!-- 无数据显示 -->
    <noData v-show="count===0" ref="noData" :msg="msg"></noData>
    <!-- <div v-show="count===0" class="noDataShow">
      <img src="@/assets/image/kong.png" alt="">
      <p>您还没有收藏的资料~</p>
    </div> -->
  </div>
</template>

<script>
import { mycollection } from '@/api/center.js'
import tool from '@/utils/tools.js'
import headers from '@/components/topTitle/index.vue'
import noData from '@/components/noDataBox/index.vue'
export default {
  name: '',
  components: { headers, noData },

  filters: {
    formatDate(time) {
      if (time !== null && time !== '') {
        time = time * 1000
        const date = new Date(time)
        return tool.formatDate(date, 'yyyy-MM-dd')
      } else {
        return ''
      }
    }
  },

  data() {
    return {
      headName: '收藏的资料',
      msg: '您还没有收藏的资料~',
      count: undefined,
      url_bbs: '', // 帖子详情链接
      list: [],
      params: {
        page: 1,
        limit: 10
      }
    }
  },

  computed: {},

  created() {
    this.getlist()
  },

  mounted() {},

  methods: {
    getlist() {
      mycollection(this.params).then(res => {
        this.list = res.result.list
        this.count = res.result.count
        console.log(res)
      }).catch(err => {
        console.log(err)
      })
    },
    threadDetail(item) {
      window.open(item.url_bbs, '_blank')
    },
    userArchive(uid) {
      const routeUrl = this.$router.resolve({
        path: '/archive',
        query: { u: uid }
      })
      window.open(routeUrl.href, '_blank')
    },

    // 分页
    handleCurrentChange(val) {
      // 当前点击页数
      this.params.page = val
      this.getlist()
    }
  }
}
</script>

<style lang="scss" scoped>
.browsePost{
	width: 965px;
	background-color: #fff;
	// padding: 0 30px 20px 30px;
  padding: 0 0 20px 0;
	min-height: 900px;
  position: relative;
	font-family: 'PingFangSC-Regular';
}
.myclass-title {
	padding: 26px 60px 12px 10px;
	width: 100%;
	font-size: 19px;
	position: relative;
	text-align: left;
}
.title-border{
	position: absolute;
	left: 0;
	bottom: 16px;
	width: 4px;
	height: 20px;
	z-index: 2;
	background: #f04e4f;
}
.noDataShow{
	position: absolute;
	width: 180px;
	height: 180px;
	top: 50%;
	left: 50%;
	margin-top: -90px;
	// margin-left: -180px;
}
.noDataShow img {
	display: block;
	margin: auto;
}
.noDataShow p {
	text-align: center;
	font-size: 14px;
	color: #999999;
	margin-top: 30px;
}

.collect_list{
  padding: 0px 30px;
  background-color: #fff;
}
.zhul_sy_zyList{
	float: left;
	width: 100%;
	border-bottom: 1px solid #DDDDDD;
	padding: 16px 0;
}
.zhul_nzy_limg{
	float: left;
	width: 164px;
	height: 102px;
	padding-top: 3px;
	margin-right: 15px;
}
.zhul_nzy_limg img{
	width: 100%;
	height: 100%;
	cursor: pointer;
}
.zhul_nzy_rinfo{
	float: right;
	width: 746px;
	background-color:#fff;
}
.zhul_nzy_rinfo_long{
	float: right;
	width: 100%;
	background-color: #fff;
}
.zhul_zy_rtop{
	float: left;
	width: 100%;
	line-height: 28px;
}
.zhul_zy_rtit{
	float: left;
	font-size: 24px;
	max-width: 500px;
	color: #444;
	font-weight: bold;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	cursor: pointer;
}
.zhul_zy_hot1{
	float: left;
	display: block;
	background: #f9cb2b;
	color: #fff;
	font-size: 12px;
	height: 24px;
	line-height: 24px;
	padding: 0 6px;
	border-radius: 2px;
	position: relative;
	min-width: 24px;
	text-align: center;
	top: 1px;
	left: 5px;
}
.zhul_zy_hot{
	float: left;
  display: block;
  background: #ee2e2e;
  color: #fff;
  font-size: 12px;
  height: 24px;
  line-height: 24px;
  padding: 0 6px;
  border-radius: 2px;
  position: relative;
	text-align: center;
	max-width: 24px;
  top: 1px;
  left: 10px;
}
.item_msg{
	float: left;
	margin: 6px 0px;
	display: flex;
	align-items: center;
	color: rgb(153, 153, 153);
	text-align: left;
}
.m_l{
	margin-left: 20px;
	position: relative;
	img{
		position: absolute;
		top: 1px;
	}
	span{
		margin-left: 20px;
	}
}
.datespan{
	margin-left: 5px;
}
.content{
	float: left;
	width: 100%;
	line-height: 20px;
	font-size: 14px;
	text-align: left;
	color: #666;
	word-break: break-all;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.zhul_lb_reply{
	float: right;
	color: #999;
	padding-top: 8px;
	// .last_user{
	// 	cursor: pointer;
	// }
}
.zhul_lb_reply img{
	margin-left: 10px;
	margin-top: -2px;
}
zhul_lb_reply,.reply_name{
	margin-left: 15px;
}
.item{
	background-color: brown;
}
.feny{
	display: inline-block;
	margin-top: 20px;
}

</style>
